































































































import { Component, Vue } from 'vue-property-decorator'
import Card from '../../shared/src/components/Card.vue'
import Col from '../../shared/src/components/Col.vue'
import Row from '../../shared/src/components/Row.vue'
import Btn from '../../shared/src/components/Btn.vue'
import Logo from '../components/Logo.vue'
import ContentRow from '../components/ContentRow.vue'
import Sizzle from '../components/Sizzle.vue'
import ContactForm from '../components/ContactForm.vue'

@Component({
  components: {
    Sizzle,
    Card,
    Col,
    Row,
    Btn,
    Logo,
    ContentRow,
    ContactForm
  }
})
export default class Contact extends Vue {
  getTerms(): string {
    if (this.$route.params.code === 'WINBLD') {
      return `This Coupon is good for a discount toward your signed quote total. A minimum purchase of $5,000 or more is required to qualify. Discounted amount is based on the size and cost of the project. This coupon
        cannot be used in conjunction with any other offer. Offer valid from Oct 1st to March 31st. Only one coupon per customer. Coupon applies to initial purchase. Discount of $6,000 is estimated on the normal cost of a tennis court.`
    }
    return `Valid Coupons are good for a discount of $500 towards a court or service
        totaling $5,000 or more. Coupons cannot be used in conjunction with any other offer. Only one coupon per customer. Coupon is not valid on change orders.`
  }
}
